import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
	Alignment,
	Button,
	Classes,
	Dialog,
	Intent,
	Navbar,
} from '@blueprintjs/core';

class CustomerDeliveryAddressPickerDialog extends Component {
	constructor(props) {
		super(props);
		
		this.state = {};
	}
	
	render() {
		return <Dialog
			isOpen={true}
			usePortal={true}
			canOutsideClickClose={true}
			canEscapeKeyClose={true}
			onClose={() => this.props.closeDialog()}
			className='customer-delivery-address-picker-dialog'>
			<div className='flex flex-col flex-grow'>
				<Navbar fixedToTop={false} className='bp5-dark'>
					<Navbar.Group>
						<Navbar.Heading>{'Izberi naslov'}</Navbar.Heading>
					</Navbar.Group>
					<Navbar.Group align={Alignment.RIGHT}>
						<Button minimal={true} icon='cross' onClick={() => this.props.closeDialog()} />
					</Navbar.Group>
				</Navbar>
				<div className={Classes.DIALOG_BODY}>
					<div className='flex flex-1 flex-col'>
						{this.props.customer.delivery_addresses.map((x, idx) => <div key={idx}>
							<Button onClick={() => this.props.closeDialog(x)}>
								{x}
							</Button>
						</div>)}
					</div>
				</div>
			</div>
		</Dialog>;
	}
}
CustomerDeliveryAddressPickerDialog.propTypes = {
	closeDialog: PropTypes.func,
	customer: PropTypes.object,
};

export default CustomerDeliveryAddressPickerDialog;
