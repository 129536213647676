import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
	Icon,
	Button,
} from '@blueprintjs/core';

import { setGeneralSettings } from '../../slices/SettingsSlice';
import { saveGeneralSettings } from '../../api/Settings';

import LocalStorageHelper from '../../helpers/LocalStorageHelper';

import { ResultHeader, Result } from '../code_tables/ResultComponents';

import EditSettingsItemDialog             from './EditSettingsItemDialog';
import EditSettingsItemFileDialog         from './EditSettingsItemFileDialog';
import EditSettingsItemSwitchDialog       from './EditSettingsItemSwitchDialog';
import EditSettingsItemNumberDialog       from './EditSettingsItemNumberDialog';
import EditSettingsItemDropdownDialog     from './EditSettingsItemDropdownDialog';
import EditSettingsItemMultilineDialog    from './EditSettingsItemMultilineDialog';
import EditSettingsItemPrinterWidthDialog from './EditSettingsItemPrinterWidthDialog';

const AutoPrintTypes = {
	'none': 'Ne tiskaj',
	'a4':   'A4',
	'pos':  'POS',
};

function Results(props) {
	const {
		item,
		openEditSettingsItemDialog,
		subject_types,
	} = props;
	
	return <div className='results mb-4'>
		<div className='results-table mt-4'>
			<ResultHeader columnIdx={1} title='Razno' />
			<ResultHeader columnIdx={2} title='Nastavitev' />
			<ResultHeader columnIdx={3} title='Vrednost' />
		</div>
		
		<div className='results-table' key={'settings-result-1'}>
			{[
				{ key: 'dsc_allow_editing_after_day_count', title: 'Evidenca/DOC št. dni za urejanje', value: item['dsc_allow_editing_after_day_count'] },
			].map(property => {
				let key   = property.key;
				let value = property.value;
				let type  = 'general';
				
				let new_item = item;
				let extra    = {};
				if (key == 'dsc_allow_editing_after_day_count') {
					new_item[key] = value;
					extra.comment = 'Pomen vrednosti: -1 je brez omejitev, 0 popolnoma prepreči urejanje, 1 pomeni \'danes\', 2 pomeni \'danes\' in \'včeraj\', itd';
					type          = 'number';
				}
				
				return <React.Fragment key={'settings-result--property-' + key}>
					<Result columnIdx={1} child={
						<Button
							icon='edit'
							intent='primary'
							minimal={true}
							small={true}
							onClick={() => {
								openEditSettingsItemDialog(
									type,
									new_item,
									key,
									extra
								);
							}} />
					} />
					<Result columnIdx={2} child={
						property.title
					} />
					<Result columnIdx={3} child={
						value
					} />
				</React.Fragment>;
			})}
		</div>
	</div>;
}

class ParameterSettings extends Component {
	constructor(props) {
		super(props);
		
		this.updateTableItemsFromProps   = this.updateTableItemsFromProps  .bind(this);
		this.openEditSettingsItemDialog  = this.openEditSettingsItemDialog .bind(this);
		this.closeEditSettingsItemDialog = this.closeEditSettingsItemDialog.bind(this);
		
		const {
			item,
		} = this.updateTableItemsFromProps(props);
		
		this.state = {
			item,
			edit_settings_item_dialog_open:         false,
			edit_settings_item_dialog_item:         null,
			edit_settings_item_dialog_property_key: null,
			edit_settings_item_dialog_extra:        null,
		};
	}
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.general_settings != this.state.item) {
			this.setState(
				this.updateTableItemsFromProps(this.props)
			);
		}
	}
	
	updateTableItemsFromProps(props) {
		return {
			item: props.general_settings,
		};
	}
	
	openEditSettingsItemDialog(type, item, property_key, extra) {
		this.setState({
			edit_settings_item_dialog_open:         true,
			edit_settings_item_dialog_type:         type,
			edit_settings_item_dialog_item:         item,
			edit_settings_item_dialog_property_key: property_key,
			edit_settings_item_dialog_extra:        extra ?? {},
		});
	}
	async closeEditSettingsItemDialog(item, property_key, new_value) {
		this.setState({
			edit_settings_item_dialog_open:         false,
			edit_settings_item_dialog_type:         null,
			edit_settings_item_dialog_item:         null,
			edit_settings_item_dialog_property_key: null,
			edit_settings_item_dialog_extra:        null,
		});
		
		if (item !== undefined) {
			let new_item = {...item};
			new_item[property_key] = new_value;
			
			this.props.dispatch(setGeneralSettings(new_item));
			
			const dispatch = this.props.dispatch;
			const token    = this.props.token;
			
			new_item = await saveGeneralSettings(this.props.api_url, new_item, token);
			if (new_item !== null) {
				dispatch(setGeneralSettings(new_item));
			}
		}
	}
	
	render() {
		let edit_settings_dialog = null;
		if (this.state.edit_settings_item_dialog_open) {
			if (this.state.edit_settings_item_dialog_type == 'dropdown') {
				let options = [];
				if (this.state.edit_settings_item_dialog_property_key == 'pos_printer_name') {
					options = printers;
				}
				else if (this.state.edit_settings_item_dialog_property_key == 'label_printer_name') {
					options = label_printers;
				}
				else if (this.state.edit_settings_item_dialog_property_key == 'auto_print_type') {
					options = AutoPrintTypes;
				}
				
				edit_settings_dialog =
					<EditSettingsItemDropdownDialog
						item={this.state.edit_settings_item_dialog_item}
						property_key={this.state.edit_settings_item_dialog_property_key}
						extra={this.state.edit_settings_item_dialog_extra}
						closeDialog={this.closeEditSettingsItemDialog}
						options={options} />
			}
			else if (this.state.edit_settings_item_dialog_type == 'file') {
				edit_settings_dialog =
					<EditSettingsItemFileDialog
						item={this.state.edit_settings_item_dialog_item}
						property_key={this.state.edit_settings_item_dialog_property_key}
						extra={this.state.edit_settings_item_dialog_extra}
						closeDialog={this.closeEditSettingsItemDialog} />;
			}
			else if (this.state.edit_settings_item_dialog_type == 'multiline') {
				edit_settings_dialog =
					<EditSettingsItemMultilineDialog
						item={this.state.edit_settings_item_dialog_item}
						property_key={this.state.edit_settings_item_dialog_property_key}
						extra={this.state.edit_settings_item_dialog_extra}
						closeDialog={this.closeEditSettingsItemDialog} />;
			}
			else if (this.state.edit_settings_item_dialog_type == 'bool') {
				edit_settings_dialog =
					<EditSettingsItemSwitchDialog
						item={this.state.edit_settings_item_dialog_item}
						property_key={this.state.edit_settings_item_dialog_property_key}
						extra={this.state.edit_settings_item_dialog_extra}
						closeDialog={this.closeEditSettingsItemDialog} />;
			}
			else if (this.state.edit_settings_item_dialog_type == 'printer_width') {
				edit_settings_dialog =
					<EditSettingsItemPrinterWidthDialog
						item={this.state.edit_settings_item_dialog_item}
						property_key={this.state.edit_settings_item_dialog_property_key}
						extra={this.state.edit_settings_item_dialog_extra}
						closeDialog={this.closeEditSettingsItemDialog} />;
			}
			else if (this.state.edit_settings_item_dialog_type == 'number') {
				edit_settings_dialog =
					<EditSettingsItemNumberDialog
						item={this.state.edit_settings_item_dialog_item}
						property_key={this.state.edit_settings_item_dialog_property_key}
						extra={this.state.edit_settings_item_dialog_extra}
						closeDialog={this.closeEditSettingsItemDialog} />;
			}
			else {
				edit_settings_dialog =
					<EditSettingsItemDialog
						item={this.state.edit_settings_item_dialog_item}
						property_key={this.state.edit_settings_item_dialog_property_key}
						extra={this.state.edit_settings_item_dialog_extra}
						closeDialog={this.closeEditSettingsItemDialog} />;
			}
		}
		
		return <>
			{edit_settings_dialog}
			
			<div className='flex flex-col flex-grow general-settings-list'>
				<div className='flex-1 pl-4 pr-4'>
					<div className='flex-grow overflow-y-auto' style={{ flexBasis: '0' }}>
						<Results
							item={this.state.item}
							openEditSettingsItemDialog={this.openEditSettingsItemDialog}
							client={this.props.client} />
					</div>
				</div>
			</div>
		</>;
	}
}
ParameterSettings.propTypes = {
};

function mapStateToProps(state) {
	return {
		general_settings: state.SettingsSlice.general,
		api_url:          state.SettingsSlice.api_url,
		token:            state.UserSlice.token,
		printers:         state.AppSlice.printers,
		label_printers:   state.AppSlice.label_printers,
		client:           state.ConstantsSlice.client,
	};
}

export default connect(mapStateToProps)(ParameterSettings);
