import React, { Component } from 'react';
import { connect } from 'react-redux';
import uuid from 'uuid';

import moment from 'moment';

import {
	Button,
	ButtonGroup,
	Checkbox,
	Alert,
	Intent,
	Tooltip,
	Dialog,
	ProgressBar,
	Classes,
} from '@blueprintjs/core';

import { loadItems } from '../../api/CodeTables';
import {
	saveDeliveryNote,
	loadDeliveryNotes,
	loadStockInventoryItems,
} from '../../api/Warehouses';
import {
	saveItem,
} from '../../api/Items';
import { saveCodeTableItem } from '../../api/CodeTables';
import AddEditDeliveryNoteDialog from './AddEditDeliveryNoteDialog';
import PriceHelper from '../../helpers/PriceHelper';
import InvoiceCreator from '../../helpers/InvoiceCreator';
import InvoiceHelper from '../../helpers/Invoice';

import { enqueueInvoiceOnA4PrinterQueue, enqueueItemOnLabelPrinterQueue } from '../../slices/AppSlice';

import { ResultHeader, Result, MultiSelectFilter, DateRangeFilter, TextFilter } from '../code_tables/ResultComponents';

function Results(props) {
	const {
		table_items,
		table_items_sort_ids,
		openAddEditItemDialog,
		tableItemsFilters,
		originalTableItemsFilters,
		handleFilterChange,
		users,
		customers,
		tax_rates,
		table_items_checked_ids,
		rowCheckStateChanged,
		allRowsCheckStateChanged,
	} = props;
	
	const users_with_titles = {};
	for (let id_user in users) {
		users_with_titles[id_user] = { id_user, title: users[id_user].name + ' ' + users[id_user].surname };
	}
	
	const types = {
		'delivery': 'Dobavnica',
		'return':   'Vračilo v zalogo',
	};
	
	const types_with_titles = {};
	for (let type in types) {
		types_with_titles[type] = { value: type, title: types[type] };
	}
	
	let total_price_without_vat = 0;
	let total_price_with_vat    = 0;
	
	const check_count = table_items_sort_ids.filter(id => table_items_checked_ids[id] === true).length;
	const all_checked = table_items_sort_ids.length == check_count;
	
	return <>
		<div className='results mt-4 mb-4'>
			<div className='results-table overflow-y-auto sticky-header'>
				<ResultHeader columnIdx={1} title={
					<Checkbox
						checked={check_count > 0}
						indeterminate={!all_checked && check_count > 0}
						onChange={event => allRowsCheckStateChanged(event.target.checked)} />
				} />
				<ResultHeader columnIdx={2} title='Urejanje' />
				<ResultHeader
					columnIdx={3} title='Številka dobavnice' />
				<ResultHeader
					columnIdx={4} title='Šifra' />
				<ResultHeader
					columnIdx={5} title='Naziv stranke' />
				<ResultHeader
					columnIdx={6} title='Datum dobavnice' />
				<ResultHeader
					columnIdx={7} title='Datum izdaje' />
				<ResultHeader
					columnIdx={8} title='Številka dobavnice' />
				<ResultHeader
					columnIdx={9} title='Vrsta dobavnice' />
				<ResultHeader
					columnIdx={10} title='Uporabnik' />
				<ResultHeader
					columnIdx={11} cls='text-right' title='Znesek brez DDV' />
				<ResultHeader
					columnIdx={12} cls='text-right' title='Znesek z DDV' />
				
				<Result columnIdx={1} cls='filters-col' />
				<Result columnIdx={2} cls='filters-col' />
				<Result columnIdx={3} cls='filters-col' child={
					<TextFilter
						value={tableItemsFilters.document_number}
						onChange={ event => handleFilterChange('document_number', event) } />
				} />
				<Result columnIdx={4} cls='filters-col' child={
					<TextFilter
						value={tableItemsFilters.customer_internal_code}
						onChange={ event => handleFilterChange('customer_internal_code', event) } />
				} />
				<Result columnIdx={5} cls='filters-col' child={
					<TextFilter
						value={tableItemsFilters.customer_name}
						onChange={ event => handleFilterChange('customer_name', event) } />
				} />
				<Result columnIdx={6} cls='filters-col' child={
					<DateRangeFilter
						filter_values={tableItemsFilters.document_date_range}
						original_start_date={originalTableItemsFilters.document_date_range[0]}
						original_end_date={originalTableItemsFilters.document_date_range[1]}
						onFilterChange={values => handleFilterChange('document_date_range', values)}
						short_year={true} />
				} />
				<Result columnIdx={7} cls='filters-col' child={
					<DateRangeFilter
						filter_values={tableItemsFilters.issue_date_range}
						original_start_date={originalTableItemsFilters.issue_date_range[0]}
						original_end_date={originalTableItemsFilters.issue_date_range[1]}
						onFilterChange={values => handleFilterChange('issue_date_range', values)}
						short_year={true} />
				} />
				<Result columnIdx={8} cls='filters-col' child={
					<TextFilter
						value={tableItemsFilters.order_number}
						onChange={ event => handleFilterChange('order_number', event) } />
				} />
				<Result columnIdx={9} cls='filters-col' child={
					<div className='flex flex-row'>
						<MultiSelectFilter
							empty_title    ='Izberi'
							value_field    ='value'
							title_field    ='title'
							items          ={types_with_titles}
							filtered_keys  ={tableItemsFilters.types}
							displaySort    ={(a, b) => a.title < b.title ? -1 : (a.title > b.title ? 1 : 0)}
							onFilterChange ={values => handleFilterChange('types', values)} />
					</div>
				} />
				<Result columnIdx={10} cls='filters-col' child={
					<div className='flex flex-row'>
						<MultiSelectFilter
							empty_title    ='Izberi'
							value_field    ='id_user'
							title_field    ='title'
							items          ={users_with_titles}
							filtered_keys  ={tableItemsFilters.id_users}
							displaySort    ={(a, b) => a.title < b.title ? -1 : (a.title > b.title ? 1 : 0)}
							onFilterChange ={values => handleFilterChange('id_users', values)} />
					</div>
				} />
				<Result columnIdx={11} cls='filters-col' child={
					null
				} />
				<Result columnIdx={12} cls='filters-col' child={
					null
				} />
				
				{table_items_sort_ids.map((id, idx) => {
					const item     = table_items[id];
					const customer = customers[item.id_customer] || null;
					const user     = users[item.id_user];
					
					let price_without_vat = 0;
					let price_with_vat    = 0;
					for (let i=0; i<(item.items ?? []).length; i++) {
						const purchase_price_without_vat =
							parseFloat(item.items[i].purchase_price_without_vat) *
							parseFloat(item.items[i].quantity) *
							(1 - parseFloat(item.items[i].purchase_discount) / 100);
						
						price_without_vat += purchase_price_without_vat;
						price_with_vat    += purchase_price_without_vat * (1 + tax_rates[item.items[i].purchase_id_tax_rate].value / 100);
					}
					
					total_price_without_vat += price_without_vat;
					total_price_with_vat    += price_with_vat;
					
					return <React.Fragment
						key={'result--result-' + id}>
						<Result columnIdx={1} child={
							<Checkbox
								checked={table_items_checked_ids[id] === true}
								onChange={event => rowCheckStateChanged(id, event.target.checked)} />
						} />
						<Result columnIdx={2} child={
							<>
								<Button
									icon='edit'
									intent='primary'
									minimal={true}
									small={true}
									onClick={() => openAddEditItemDialog(item)} />
							</>
						} />
						<Result columnIdx={3} child={ item.document_number } />
						<Result columnIdx={4} child={ customer === null || customer === undefined ? null : customer.internal_code } />
						<Result columnIdx={5} child={
							customer === null || customer === undefined ? null :
								(customer.type == 'natural' ?
									customer.surname + ' ' + customer.name
									:
									customer.company_name
								)
						} />
						<Result columnIdx={6} child={ item.document_date === null ? null : moment(item.document_date).format('DD. MM. YYYY') } />
						<Result columnIdx={7} child={ item.issue_date    === null ? null : moment(item.issue_date)   .format('DD. MM. YYYY') } />
						<Result columnIdx={8} child={ item.order_number } />
						<Result columnIdx={9} child={ types[item.type] } />
						<Result columnIdx={10} child={
							user === null || user === undefined ? null : (user.name + ' ' + user.surname)
						} />
						<Result columnIdx={11} cls='text-right font-bold' child={
							price_without_vat.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) + ' EUR'
						} />
						<Result columnIdx={12} cls='text-right font-bold' child={
							price_with_vat.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) + ' EUR'
						} />
					</React.Fragment>;
				})}
			</div>
		</div>
		<div className='results borderless mt-4 mb-4'>
			<div className='results-table'>
				<Result columnIdx={1} span={2} child={
					'Število zapisov: ' + table_items_sort_ids.length
				} />
				<Result columnIdx={3} child={''} />
				<Result columnIdx={4} child={''} />
				<Result columnIdx={5} child={''} />
				<Result columnIdx={6} child={''} />
				<Result columnIdx={7} child={''} />
				<Result columnIdx={8} child={''} />
				<Result columnIdx={9} child={''} />
				<Result columnIdx={10} child={''} />
				<Result columnIdx={11} cls='text-right font-bold' child={
					total_price_without_vat
						.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) + ' EUR'
				} />
				<Result columnIdx={12} cls='text-right font-bold' child={
					total_price_with_vat
						.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) + ' EUR'
				} />
			</div>
		</div>
	</>;
}

class DeliveryNotes extends Component {
	constructor(props) {
		super(props);
		
		this._ismounted = false;
		
		this.openAddEditItemDialog      = this.openAddEditItemDialog     .bind(this);
		this.closeAddEditItemDialog     = this.closeAddEditItemDialog    .bind(this);
		this.updateTableItemsFromProps  = this.updateTableItemsFromProps .bind(this);
		this.refreshTableItemsSort      = this.refreshTableItemsSort     .bind(this);
		this.filterTableItems           = this.filterTableItems          .bind(this);
		this.handleFilterChange         = this.handleFilterChange        .bind(this);
		this.requestDeleteItem          = this.requestDeleteItem         .bind(this);
		this.deleteItem                 = this.deleteItem                .bind(this);
		this.createInvoiceFromOriginal  = this.createInvoiceFromOriginal .bind(this);
		
		const table_items_filters = {
			document_number:        '',
			customer_internal_code: '',
			customer_name:          '',
			document_date_range:    [ moment().subtract(3, 'months').toDate(), null ],
			issue_date_range:        [ null, null ],
			order_number:   '',
			types:                  [],
			id_users:               [],
		};
		
		const {
			table_items,
			table_items_sort_ids,
			table_items_filtered_ids,
		} = this.updateTableItemsFromProps(props, {
			table_items_filters,
			table_items_sort_field: 'document_number',
			table_items_sort_direction: 'DESC',
		});
		
		this.state = {
			table_items,
			table_items_sort_ids,
			table_items_filtered_ids,
			table_items_sort_field: 'document_number',
			table_items_sort_direction: 'DESC',
			table_items_filters,
			original_table_items_filters: {...table_items_filters},
			table_items_checked_ids: {},
			add_edit_item_dialog_open:             false,
			add_edit_item_dialog_id_cash_register: null,
			add_edit_item_dialog_date:             null,
			add_edit_item_dialog_start_balance:    0,
			add_edit_item_dialog_documents:        [],
			add_edit_item_dialog_item:             null,
			delete_request_id_item:                null,
			saving:                                false,
		};
	}
	
	componentDidMount() {
		this._ismounted = true;
	}
	componentWillUnmount() {
		this._ismounted = false;
	}
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		if (
			this.props.users          != prevProps.users ||
			this.props.delivery_notes != prevProps.delivery_notes
		) {
			const state = this.updateTableItemsFromProps(this.props, this.state);
			this.setState(
				state
			);
		}
	}
	
	updateTableItemsFromProps(props, state) {
		const ids = Object.keys(props.delivery_notes);
		
		let sort_ids = [];
		
		if (state !== undefined && state.table_items_sort_ids !== undefined) {
			for (let i=0; i<state.table_items_sort_ids.length; i++) {
				const id = state.table_items_sort_ids[i];
				if (ids.indexOf(id) != -1) {
					sort_ids.push(id);
				}
			}
		}
		
		for (let i=0; i<ids.length; i++) {
			const id = ids[i];
			if (sort_ids.indexOf(id) == -1) {
				sort_ids.push(id);
			}
		}
		
		const new_state = {
			invoices: props.invoices,
			table_items: props.delivery_notes,
			table_items_sort_ids: sort_ids,
			table_items_filtered_ids: ids,
			table_items_sort_field: state.table_items_sort_field,
			table_items_sort_direction: state.table_items_sort_direction,
		};
		
		const {
			table_items_filtered_ids,
			table_items_filters,
			table_items_sort_ids,
		} = this.filterTableItems(state.table_items_filters, new_state, true);
		
		new_state.table_items_filtered_ids = table_items_filtered_ids;
		new_state.table_items_filters      = table_items_filters;
		new_state.table_items_sort_ids     = table_items_sort_ids;
		
		return new_state;
	}
	
	setTableItemsSort(field) {
		let direction = 'ASC';
		if (this.state.table_items_sort_field == field) {
			direction = (this.state.table_items_sort_direction == 'ASC' ? 'DESC' : 'ASC');
		}
		
		this.setState({
			table_items_sort_field:     field,
			table_items_sort_direction: direction,
		});
		
		this.refreshTableItemsSort(field, direction);
	}
	
	refreshTableItemsSort(field, direction, table_items_filtered_ids, state, return_only) {
		state                    = state || this.state;
		table_items_filtered_ids = table_items_filtered_ids || state.table_items_filtered_ids;
		
		const ids = table_items_filtered_ids;
		ids.sort((a_key, b_key) => {
			let a = '';
			let b = '';
			let dir = direction;
			
			a = state.table_items[a_key][field];
			b = state.table_items[b_key][field];
			
			return (a < b ? -1 : a > b ? 1 : 0) * (dir == 'ASC' ? 1 : -1);
		});
		
		const new_state = {
			table_items_sort_ids: ids,
		};
		
		if (!return_only) {
			this.setState(new_state);
		}
		return new_state;
	}
	
	toIsoString(date) {
		const year  = date.getFullYear();
		const month = date.getMonth() + 1;
		const day   = date.getDate();
		
		return year + '-' + (month < 10 ? '0' + month : month) + '-' + (day < 10 ? '0' + day : day);
	}
	
	filterTableItems(filters, state, return_only) {
		state = state || this.state;
		console.log({
			filters,
			document_date_range: filters.document_date_range,
			issue_date_range: filters.issue_date_range,
		});
		
		const filters_document_number        = filters.document_number       .toUpperCase();
		const filters_customer_internal_code = filters.customer_internal_code.toUpperCase();
		const filters_customer_name          = filters.customer_name         .toUpperCase();
		const filters_document_date_start    = filters.document_date_range[0] === null ? null : this.toIsoString(filters.document_date_range[0]);
		const filters_document_date_end      = filters.document_date_range[1] === null ? null : this.toIsoString(filters.document_date_range[1]);
		const filters_issue_date_start        = filters.issue_date_range[0]     === null ? null : this.toIsoString(filters.issue_date_range[0]);
		const filters_issue_date_end          = filters.issue_date_range[1]     === null ? null : this.toIsoString(filters.issue_date_range[1]);
		const filters_order_number   = filters.order_number.toUpperCase();
		const filters_types                  = filters.types;
		const filters_id_users               = filters.id_users;
		
		const ids = Object.keys(state.table_items);
		const filtered_ids = ids.filter(id => {
			const item = state.table_items[id];
			
			if (filters_document_number != '' && (item.document_number === null || item.document_number.toUpperCase().indexOf(filters_document_number) == -1)) {
				return false;
			}
			
			if (filters_customer_internal_code != '' && (item.id_customer === null || this.props.customers[item.id_customer] === undefined || this.props.customers[item.id_customer].internal_code != filters_customer_internal_code)) {
				return false;
			}
			
			if (filters_customer_name != '' && (item.id_customer === null || this.props.customers[item.id_customer] === undefined || (this.props.customers[item.id_customer].surname + ' ' + this.props.customers[item.id_customer].name + ' ' + this.props.customers[item.id_customer].company_name).toUpperCase().indexOf(filters_customer_name) == -1)) {
				return false;
			}
			
			if (filters_document_date_start !== null && item.document_date !== null && this.toIsoString(new Date(item.document_date)) < filters_document_date_start) {
				return false;
			}
			if (filters_document_date_end !== null && item.document_date !== null && this.toIsoString(new Date(item.document_date)) > filters_document_date_end) {
				return false;
			}
			
			if (filters_issue_date_start !== null && item.issue_date !== null && this.toIsoString(new Date(item.issue_date)) < filters_issue_date_start) {
				return false;
			}
			if (filters_issue_date_end !== null && item.issue_date !== null && this.toIsoString(new Date(item.issue_date)) > filters_issue_date_end) {
				return false;
			}
			
			if (filters_order_number != '' && (item.order_number === null || item.order_number.toUpperCase().indexOf(filters_order_number) == -1)) {
				return false;
			}
			
			if (filters_id_users.length > 0) {
				if (item.id_user === null || filters_id_users.indexOf(item.id_user) == -1) {
					return false;
				}
			}
			
			if (filters_types.length > 0) {
				if (item.type === null || filters_types.indexOf(item.type) == -1) {
					return false;
				}
			}
			
			return true;
		});
		
		const new_state = {
			table_items_filtered_ids: filtered_ids,
			table_items_filters:      filters,
		};
		
		if (!return_only) {
			this.setState(new_state);
		}
		
		const { table_items_sort_ids } = this.refreshTableItemsSort(
			state.table_items_sort_field,
			state.table_items_sort_direction,
			filtered_ids,
			{
				table_items_filtered_ids: new_state,
				table_items_filters:      new_state,
				table_items:              state.table_items,
			},
			return_only
		);
		
		new_state.table_items_sort_ids = table_items_sort_ids;
		
		return new_state;
	}
	
	handleFilterChange(field_name, event) {
		const new_filters = {
			document_number:        this.state.table_items_filters.document_number,
			customer_internal_code: this.state.table_items_filters.customer_internal_code,
			customer_name:          this.state.table_items_filters.customer_name,
			document_date_range:    this.state.table_items_filters.document_date_range,
			issue_date_range:        this.state.table_items_filters.issue_date_range,
			order_number:   this.state.table_items_filters.order_number,
			types:                  this.state.table_items_filters.types,
			id_users:               this.state.table_items_filters.id_users,
		};
		
		let val = event;
		if (val === null) { }
		if (val instanceof Date) { }
		else if (Array.isArray(val)) { }
		else if (val instanceof Object) {
			val = event.target.value;
		}
		new_filters[field_name] = val;
		
		this.filterTableItems(new_filters);
	}
	
	openAddEditItemDialog(item) {
		this.setState({
			add_edit_item_dialog_open: true,
			add_edit_item_dialog_item: item,
		});
	}
	async closeAddEditItemDialog(item, ignore_prices_for_id_delivery_note_items) {
		if (item === undefined) {
			this.setState({
				add_edit_item_dialog_open: false,
			});
			return;
		}
		
		this.setState({ saving: true });
		
		const dispatch = this.props.dispatch;
		const token    = this.props.token;
		
		await saveDeliveryNote(
			this.props.api_url,
			item,
			token
		);
		//loadDeliveryNotes(this.props.api_url, dispatch, token);
		//loadStockInventoryItems      (this.props.api_url, dispatch, token);
		
		console.log({ ITEM2: item });
		// save new delivery address, if needed
		const delivery_address   = item.delivery_address.trim();
		if (delivery_address.length > 0) {
			let   customer           = this.props.customers[item.id_customer];
			const delivery_addresses = [ ...customer.delivery_addresses ];
			
			// if found, remove existing address
			// then add new address to start
			const delivery_address_lower = delivery_address.toLowerCase();
			for (let i=0; i<delivery_addresses.length; i++) {
				const address = delivery_addresses[i].toLowerCase();
				if (address == delivery_address_lower) {
					delivery_addresses.splice(i, 1);
					break;
				}
			}
			
			delivery_addresses.splice(0, 0, delivery_address);
			
			customer = {
				...customer,
				delivery_addresses,
			};
			await saveCodeTableItem(this.props.api_url, 'customers', customer, token);
		}
		
		this.setState({
			add_edit_item_dialog_open: false,
			saving: false,
		});
	}
	
	requestDeleteItem(id_item) {
		this.setState({ delete_request_id_item: id_item });
	}
	async deleteItem(id_item) {
		const dispatch = this.props.dispatch;
		const token    = this.props.token;
		
		//await deleteCashRegisterDailyState(this.props.api_url, id_item, token);
	}
	
	createInvoiceFromOriginal(id, invoice_type) {
		const item = this.state.table_items[id];
		
		const new_item = InvoiceCreator.CreateInvoiceFromInitialData({
			id_invoice:         uuid.v4(),
			id_customer:        item.id_customer,
			invoice_date:       new Date(),
			invoice_type,
			note:               '',
			payment_date:       null,
			reference:          item.document_number,
			reservation:        '',
			service_date_end:   moment(item.document_date).toDate(),
			service_date_start: moment(item.document_date).toDate(),
			invoice_amount:     0,
			id_cash_register:   this.props.current_id_cash_register,
			advance_payment_amount: 0,
			credit_note_type:   null,
			items: item.items.map((item_item, idx) => {
				const item_item_item = this.props.items[item_item.id_item];
				const measuring_unit = this.props.measuring_units[item_item_item.id_measuring_unit];
				
				const id_price_list = PriceHelper.FindBestPriceListMatch(new Date(), item_item_item.price_lists);
				const price_list    = item_item_item.price_lists[id_price_list];
				const tax_rate      = this.props.tax_rates[price_list.id_tax_rate];
				
				console.log({ item, item_item, item_item_item });
				
				return InvoiceCreator.CreateInvoiceItemFromInitialData({
					id_invoice_item:                         uuid.v4(),
					description:                             item_item_item.description,
					discount:                                0,
					id_item:                                 item_item_item.id_item,
					id_item_price_list:                      id_price_list,
					id_tax_rate:                             price_list.id_tax_rate,
					item_measuring_unit_code:                measuring_unit.title,
					item_measuring_unit_decimal_digit_count: measuring_unit.decimal_digit_count,
					price:                                   parseFloat(item_item.price),
					quantity:                                parseFloat(item_item.quantity),
					tax_rate:                                parseFloat(tax_rate.value),
					approved_percent:                        100,
					item_type:                               item_item_item.type,
					item_ean:                                item_item_item.ean,
					item_title:                              item_item_item.title,
					ord:                                     idx,
					taxable:                                 tax_rate.taxable,
					item_internal_code:                      item_item_item.internal_code,
					id_warehouse: item_item_item.type != 'item' ? null : InvoiceHelper.findCurrentIdWarehouse(
						this.props.current_id_cash_register === undefined || this.props.current_id_cash_register === null ? null :
							this.props.cash_registers[this.props.current_id_cash_register],
						this.props.warehouses
					),
				});
			}),
			payments: [],
			original_id_invoice: '',
			original_invoice_type: '',
		});
		this.props.openInvoiceDialog(new_item, new_item.invoice_type);
		
		this.setState({
			table_items_checked_ids: {},
		});
	}
	
	render() {
		const check_count = this.state.table_items_sort_ids.filter(id => this.state.table_items_checked_ids[id] === true).length;
		
		return <>
			{!this.state.add_edit_item_dialog_open ? null :
				<AddEditDeliveryNoteDialog
					closeDialog={this.closeAddEditItemDialog}
					item={this.state.add_edit_item_dialog_item} />
			}
			
			{this.state.delete_request_id_item === null ? null :
				<Alert
					cancelButtonText='Prekliči'
					confirmButtonText='Briši'
					canEscapeKeyCancel={true}
					canOutsideClickCancel={true}
					icon='trash'
					intent={Intent.DANGER}
					isOpen={true}
					onConfirm={() => {
						this.deleteItem(this.state.delete_request_id_item);
						this.setState({ delete_request_id_item: null });
					}}
					onCancel={() => {
						this.setState({ delete_request_id_item: null });
					}}>
					Ali res želite izbrisati ta zapis?
				</Alert>
			}
			
			{!this.state.saving ? null :
				<Dialog
					isOpen={true}
					usePortal={true}
					canOutsideClickClose={false}
					canEscapeKeyClose={false}>
					<div className='flex flex-col flex-grow'>
						<div className={Classes.DIALOG_BODY}>
							<ProgressBar animate={true} intent='primary' />
							
							<div className='text-xl text-center font-light pt-5'>Shranjevanje</div>
						</div>
					</div>
				</Dialog>
			}
			
			<div className='flex flex-col flex-grow base-list stock-acquisition-document-list'>
				<div className='pl-4 pt-4'>
					<ButtonGroup>
						<Button
							intent='success'
							icon='plus'
							className='align-middle'
							onClick={() => this.openAddEditItemDialog(null)}>
							Nova dobavnica
						</Button>
					</ButtonGroup>
					
					<ButtonGroup className='ml-2'>
						<Button
							intent='primary'
							icon='print'
							className='align-middle'
							disabled={check_count == 0}
							onClick={() => {
								this.state.table_items_sort_ids
									.filter(id => this.state.table_items_checked_ids[id] === true)
									.forEach(id => {
										const item = this.state.table_items[id];
										this.props.dispatch(enqueueInvoiceOnA4PrinterQueue({ type: 'delivery-note', lang: 'sl', delivery_note: item }));
									});
							}}>
							Natisni
						</Button>
					</ButtonGroup>
					
					<ButtonGroup className='ml-2'>
						<Button
							intent='warning'
							icon='inheritance'
							className='align-middle'
							disabled={check_count != 1}
							onClick={() => {
								this.state.table_items_sort_ids
									.filter(id => this.state.table_items_checked_ids[id] === true)
									.forEach(id => {
										this.createInvoiceFromOriginal(id, 'invoice');
									});
							}}>
							Izdelaj fakturo
						</Button>
					</ButtonGroup>
				</div>
				
				<div className='flex-1 pl-4 pr-4'>
					<div className='flex-grow' style={{ flexBasis: '0' }}>
						<Results
							table_items              ={this.state.table_items}
							table_items_sort_ids     ={this.state.table_items_sort_ids}
							openAddEditItemDialog    ={this.openAddEditItemDialog}
							tableItemsFilters        ={this.state.table_items_filters}
							originalTableItemsFilters={this.state.original_table_items_filters}
							handleFilterChange       ={this.handleFilterChange}
							users                    ={this.props.users}
							customers                ={this.props.customers}
							tax_rates                ={this.props.tax_rates}
							table_items_checked_ids={this.state.table_items_checked_ids}
							rowCheckStateChanged={(id, state) => {
								let table_items_checked_ids = this.state.table_items_checked_ids;
								
								if (state === false) {
									delete table_items_checked_ids[id];
								}
								else {
									table_items_checked_ids[id] = state;
								}
								
								this.setState({ table_items_checked_ids });
							}}
							allRowsCheckStateChanged={state => {
								const table_items_checked_ids = {};
								for (let i=0; i<this.state.table_items_sort_ids.length; i++) {
									table_items_checked_ids[this.state.table_items_sort_ids[i]] = state;
								}
								
								this.setState({ table_items_checked_ids });
							}} />
					</div>
				</div>
			</div>
		</>;
	}
}
DeliveryNotes.propTypes = {
};

function mapStateToProps(state) {
	return {
		api_url:        state.CashRegisterSlice.api_url,
		cash_registers: state.CashRegisterSlice.cash_registers,
		token:          state.UserSlice.token,
		user:           state.UserSlice.user,
		users:          state.UserSlice.users,
		items:          state.CodeTablesSlice.items,
		customers:      state.CodeTablesSlice.customers,
		tax_rates:      state.CodeTablesSlice.tax_rates,
		measuring_units: state.CodeTablesSlice.measuring_units,
		warehouses:     state.CodeTablesSlice.warehouses,
		delivery_notes: state.WarehouseSlice.delivery_notes,
	};
}

export default connect(mapStateToProps)(DeliveryNotes);
