import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
	Button,
} from '@blueprintjs/core';

import { setGeneralSettings } from '../../slices/SettingsSlice';
import { saveGeneralSettings } from '../../api/Settings';

import LocalStorageHelper from '../../helpers/LocalStorageHelper';

import { ResultHeader, Result } from '../code_tables/ResultComponents';

import EditSettingsItemColorPickerDialog from './EditSettingsItemColorPickerDialog';
import EditSettingsItemDialog            from './EditSettingsItemDialog';

function Results(props) {
	const {
		item,
		openEditSettingsItemDialog,
	} = props;
	
	return <div className='results mt-4 mb-4'>
		<div className='results-table'>
			<ResultHeader columnIdx={1} title='Barve statusov' />
			<ResultHeader columnIdx={2} title='Status' />
			<ResultHeader columnIdx={3} title='Vrednost' />
		</div>
		
		<div className='results-table' key={'settings-result-1'}>
			{[
				{ key: 'color_status_new',                      title: 'Nova'           },
				{ key: 'color_status_waiting_for_confirmation', title: 'V potrjevanju'  },
				{ key: 'color_status_offer_sent',               title: 'Izdana ponudba' },
				{ key: 'color_status_confirmed',                title: 'Potrjena'       },
				{ key: 'color_status_advance_invoice_sent',     title: 'Izdan avans'    },
				{ key: 'color_status_closed',                   title: 'Plačana'        },
				{ key: 'color_status_reversed',                 title: 'Stornirana'     },
				{ key: 'color_status_no_show',                  title: 'Neprihod'       },
				{ key: 'color_status_not_for_rent',             title: 'Ni za oddajo'   },
				{ key: 'color_status_deleting',                 title: 'Brisana'        },
				{ key: 'color_status_invalid',                  title: 'Neveljavno'     },
				{ key: 'color_custom_status_1',                 title: 'Po meri 1'      },
				{ key: 'color_custom_status_2',                 title: 'Po meri 2'      },
				{ key: 'color_custom_status_3',                 title: 'Po meri 3'      },
				{ key: 'color_custom_status_4',                 title: 'Po meri 4'      },
				{ key: 'color_custom_status_5',                 title: 'Po meri 5'      },
				{ key: 'color_custom_status_6',                 title: 'Po meri 6'      },
				{ key: 'color_custom_status_7',                 title: 'Po meri 7'      },
			].map(property => {
				let value = item[property.key];
				let type  = 'color-picker';
				
				return <React.Fragment key={'settings-result--property-' + property.key}>
					<Result columnIdx={1} child={
						<Button
							icon='edit'
							intent='primary'
							minimal={true}
							small={true}
							onClick={() => {
								openEditSettingsItemDialog(
									type,
									item,
									property.key,
									'Status ' + property.title
								);
							}} />
					} />
					<Result columnIdx={2} child={
						property.title
					} />
					<Result columnIdx={3} child={
						<div style={{ width: 18, height: 18, marginTop: 5, backgroundColor: value }}></div>
					} />
				</React.Fragment>;
			})}
		</div>
		
		<div className='results-table'>
			<ResultHeader columnIdx={1} title='Nazivi statusov' />
			<ResultHeader columnIdx={2} title='Status' />
			<ResultHeader columnIdx={3} title='Vrednost' />
		</div>
		
		<div className='results-table' key={'settings-result-2'}>
			{[
				{ key: 'title_custom_status_1', title: 'Po meri 1' },
				{ key: 'title_custom_status_2', title: 'Po meri 2' },
				{ key: 'title_custom_status_3', title: 'Po meri 3' },
				{ key: 'title_custom_status_4', title: 'Po meri 4' },
				{ key: 'title_custom_status_5', title: 'Po meri 5' },
				{ key: 'title_custom_status_6', title: 'Po meri 6' },
				{ key: 'title_custom_status_7', title: 'Po meri 7' },
			].map(property => {
				let value = item[property.key];
				let type  = 'general';
				
				return <React.Fragment key={'settings-result--property-' + property.key}>
					<Result columnIdx={1} child={
						<Button
							icon='edit'
							intent='primary'
							minimal={true}
							small={true}
							onClick={() => {
								openEditSettingsItemDialog(
									type,
									item,
									property.key,
									'Status ' + property.title
								);
							}} />
					} />
					<Result columnIdx={2} child={
						property.title
					} />
					<Result columnIdx={3} child={
						value
					} />
				</React.Fragment>;
			})}
		</div>
	</div>;
}

class StatusSettings extends Component {
	constructor(props) {
		super(props);
		
		this.updateTableItemsFromProps   = this.updateTableItemsFromProps  .bind(this);
		this.openEditSettingsItemDialog  = this.openEditSettingsItemDialog .bind(this);
		this.closeEditSettingsItemDialog = this.closeEditSettingsItemDialog.bind(this);
		
		const {
			item,
		} = this.updateTableItemsFromProps(props);
		
		this.state = {
			item,
			edit_settings_item_dialog_open:         false,
			edit_settings_item_dialog_item:         null,
			edit_settings_item_dialog_property_key: null,
		};
	}
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.general_settings != this.state.item) {
			this.setState(
				this.updateTableItemsFromProps(this.props)
			);
		}
	}
	
	updateTableItemsFromProps(props) {
		return {
			item: props.general_settings,
		};
	}
	
	openEditSettingsItemDialog(type, item, property_key, property_title) {
		this.setState({
			edit_settings_item_dialog_open:           true,
			edit_settings_item_dialog_type:           type,
			edit_settings_item_dialog_item:           item,
			edit_settings_item_dialog_property_key:   property_key,
			edit_settings_item_dialog_property_title: property_title,
		});
	}
	async closeEditSettingsItemDialog(item, property_key, new_value) {
		this.setState({
			edit_settings_item_dialog_open:           false,
			edit_settings_item_dialog_type:           null,
			edit_settings_item_dialog_item:           null,
			edit_settings_item_dialog_property_key:   null,
			edit_settings_item_dialog_property_title: null,
		});
		
		if (item !== undefined) {
			if (property_key == 'pos_printer_name') {
				LocalStorageHelper.SetValue(this.props.client + '__pos_printer_name', new_value);
			}
			else if (property_key == 'pos_printer_width') {
				LocalStorageHelper.SetValue(this.props.client + '__pos_printer_width', new_value);
			}
			else if (property_key == 'save_path') {
				LocalStorageHelper.SetValue(this.props.client + '__save_path', new_value);
			}
			else if (property_key == 'vat') {
				let new_item = {...item};
				
				new_item.subject_vat_registered        = new_value.subject_vat_registered;
				new_item.subject_vat_registered_reason = new_value.subject_vat_registered_reason;
				new_item.subject_vat_number            = new_value.subject_vat_number;
				
				this.props.dispatch(setGeneralSettings(new_item));
				
				const dispatch = this.props.dispatch;
				const token    = this.props.token;
				
				new_item = await saveGeneralSettings(this.props.api_url, new_item, token);
				if (new_item !== null) {
					dispatch(setGeneralSettings(new_item));
				}
			}
			else {
				let new_item = {...item};
				new_item[property_key] = new_value;
				
				this.props.dispatch(setGeneralSettings(new_item));
				
				const dispatch = this.props.dispatch;
				const token    = this.props.token;
				
				new_item = await saveGeneralSettings(this.props.api_url, new_item, token);
				if (new_item !== null) {
					dispatch(setGeneralSettings(new_item));
				}
			}
		}
	}
	
	render() {
		let edit_settings_dialog = null;
		if (this.state.edit_settings_item_dialog_open) {
			if (this.state.edit_settings_item_dialog_type == 'color-picker') {
				edit_settings_dialog =
					<EditSettingsItemColorPickerDialog
						item={this.state.edit_settings_item_dialog_item}
						property_key={this.state.edit_settings_item_dialog_property_key}
						property_title={this.state.edit_settings_item_dialog_property_title}
						closeDialog={this.closeEditSettingsItemDialog} />;
			}
			else {
				edit_settings_dialog =
					<EditSettingsItemDialog
						item={this.state.edit_settings_item_dialog_item}
						property_key={this.state.edit_settings_item_dialog_property_key}
						closeDialog={this.closeEditSettingsItemDialog} />;
			}
		}
		
		return <>
			{edit_settings_dialog}
			
			<div className='flex flex-col flex-grow general-settings-list'>
				<div className='flex-1 pl-4 pr-4'>
					<div className='flex-grow overflow-y-auto' style={{ flexBasis: '0' }}>
						<Results
							item={this.state.item}
							openEditSettingsItemDialog={this.openEditSettingsItemDialog}
							client={this.props.client} />
					</div>
				</div>
			</div>
		</>;
	}
}
StatusSettings.propTypes = {
};

function mapStateToProps(state) {
	return {
		general_settings: state.SettingsSlice.general,
		api_url:          state.SettingsSlice.api_url,
		token:            state.UserSlice.token,
		printers:         state.AppSlice.printers,
		client:           state.ConstantsSlice.client,
	};
}

export default connect(mapStateToProps)(StatusSettings);
