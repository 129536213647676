export default {
	Print: {
		CustomerDetails: {
			VATNumber:            { sl: 'ID za DDV:',     en: 'VAT No.:',     de: 'Umsatzsteuer-ID Nr.:' },
			TaxNumber:            { sl: 'Davčna št.:',    en: 'Tax number:',  de: 'Steuernummer:'        },
			CustomerInternalCode: { sl: 'Šifra stranke:', en: 'Customer ID:', de: 'Kunden Nr.:'          },
		},
		MainInvoiceDetails: {
			TimestampFormat: { sl: 'DD.MM.YYYY \\o\\b HH:mm:ss', en: 'DD.MM.YYYY \\a\\t HH:mm:ss', de: 'DD.MM.YYYY \\u\\m HH:mm:ss', },
			DateFormat:      { sl: 'DD.MM.YYYY',                 en: 'DD.MM.YYYY',                 de: 'DD.MM.YYYY',                 },
			ShortDateFormat: { sl: 'DD.MM.YY',                   en: 'DD.MM.YY',                   de: 'DD.MM.YY',                   },
			InvoiceTitles:   {
				'cash-invoice':                 { sl: 'RAČUN',                        en: 'INVOICE',                      de: 'RECHNUNG'                     },
				'invoice':                      { sl: 'RAČUN',                        en: 'INVOICE',                      de: 'RECHNUNG'                      },
				'prepayment-invoice':           { sl: 'AVANSNI RAČUN',                en: 'PREPAYMENT',                   de: 'AVANSZAHLUNG'                 },
				'credit-note':                  { sl: 'DOBROPIS',                     en: 'CREDIT NOTE',                  de: 'GUTSCHRIFT'                   },
				'offer':                        { sl: 'PONUDBA',                      en: 'OFFER',                        de: 'ANGEBOT'                      },
				'cash-register-invoice-income': { sl: 'cash-register-invoice-income', en: 'cash-register-invoice-income', de: 'cash-register-invoice-income' },
				'cash-register-invoice-outlay': { sl: 'cash-register-invoice-outlay', en: 'cash-register-invoice-outlay', de: 'cash-register-invoice-outlay' },
				'cash-register-journal':        { sl: 'cash-register-journal',        en: 'cash-register-journal',        de: 'cash-register-journal'        },
				'gift-voucher':                 { sl: 'gift-voucher',                 en: 'gift-voucher',                 de: 'gift-voucher'                 },
				'stock-acquisition':            { sl: 'stock-acquisition',            en: 'stock-acquisition',            de: 'stock-acquisition'            },
				'stock-delivery-document':      { sl: 'stock-delivery-document',      en: 'stock-delivery-document',      de: 'stock-delivery-document'      },
				'warehouse-transfer-order':     { sl: 'warehouse-transfer-order',     en: 'warehouse-transfer-order',     de: 'warehouse-transfer-order'     },
				'delivery-report':              { sl: 'delivery-report',              en: 'delivery-report',              de: 'delivery-report'              },
				'sales-invoice-book':           { sl: 'sales-invoice-book',           en: 'sales-invoice-book',           de: 'sales-invoice-book'           },
				'proforma-invoice-book':        { sl: 'proforma-invoice-book',        en: 'proforma-invoice-book',        de: 'proforma-invoice-book'        },
				'cash-prepayment-invoice':      { sl: 'RAČUN ZA PREDPLAČILO',         en: 'PREPAYMENT INVOICE',           de: 'AVANSZAHLUNG'                 },
				'guest-book':                   { sl: 'guest-book',                   en: 'guest-book',                   de: 'guest-book'                   },
				'delivery-note':                { sl: 'DOBAVNICA',                    en: 'DELIVERY NOTE',                de: 'LIEFERSCHEIN'                 },
			},
			InvoiceNumber: { sl: 'Številka:',      en: 'Number:',          de: 'Nummer:'         },
			Place:         { sl: 'Kraj:',          en: 'Place:',           de: 'Ort:'            },
			Timestamp:     { sl: 'Datum:',         en: 'Date:',            de: 'Datum:'          },
			ServiceDate:   { sl: 'Opravljeno:',    en: 'Service date:',    de: 'Durchgeführt:'   },
			Reference:     { sl: 'Referenca:',     en: 'Reference:',       de: 'Referenz:'       },
			Refer:         { sl: 'Sklic:',         en: 'Refer.:',          de: 'Bezugnahme::'    },
			DeliveryNote:  { sl: 'Dobavnica:',     en: 'Delivery note:',   de: 'Lieferschein:'   },
			PaymentDate:   { sl: 'Valuta:',        en: 'Payment due:',     de: 'Zahlung fällig:' },
			DateOfPayment: { sl: 'Datum plačila:', en: 'Date of payment:', de: 'Zahlungsdatum:'  },
			ValidityDate:  { sl: 'Veljavnost:',    en: 'Valid until:',     de: 'Gülting bis:'    },
			Reservation:   { sl: 'Rezervacija:',   en: 'Reservation:',     de: 'Reservierung:'   },
			UserReceivedBy:  { sl: 'Prevzel:',     en: 'Prevzel:',         de: 'Prevzel:'        },
			UserIssuedBy:    { sl: 'Izdal:',       en: 'Izdal:',           de: 'Izdal:'          },
			UserFulfilledBy: { sl: 'Opravil:',     en: 'Opravil:',         de: 'Opravil:'        },
			EstimatedServiceDate: { sl: 'Obdobje storitve (predvideno):', en: 'Date of service (estimated):', de: 'Servicedatum (geschätzt):' },
			Paid:          { sl: 'PLAČANO', en: 'PAID', de: 'BEZAHLT' },
		},
		InvoiceItemsTable: {
			ItemInternalCode:      { sl: 'Šifra',                   en: 'Code',                 de: 'Code'            },
			ItemTitle:             { sl: 'Opis artikla / storitve', en: 'Description',          de: 'Beschreibung'    },
			Quantity:              { sl: 'Kol.',                    en: 'Qty.',                 de: 'Menge'           },
			ItemMeasuringUnitCode: { sl: 'EM',                      en: 'Unit',                 de: 'Einheit'         },
			Price:                 { sl: 'Cena\nz DDV',             en: 'Unit price',           de: 'Stückpreis'      },
			Discount:              { sl: 'Popust\n%',               en: 'Discount\n%',          de: 'Rabatt\n%'       },
			AmountWithoutVAT:      { sl: 'Vrednost\nbrez DDV',      en: 'Amount\nexcl. VAT',    de: 'Wert\no. MwSt.'  },
			VAT:                   { sl: 'DDV\n%',                  en: 'VAT\n%',               de: 'MwSt.\n%'        },
			AmountWithVAT:         { sl: 'Vrednost\nz DDV',         en: 'Amount\nwith VAT',     de: 'Wert\nmit MwSt.' },
		},
		TaxesTable: {
			TaxRate:    { sl: 'Stopnja',     en: 'VAT Rate',         de: 'Steuersatz'            },
			TaxBase:    { sl: 'Osnova',      en: 'Tax Base',         de: 'Steuergrundlage'       },
			TaxAmount:  { sl: 'Davek',       en: 'Tax',              de: 'Steuer'                },
			Total:      { sl: 'Skupaj',      en: 'Total',            de: 'Gesamtsumme'           },
			Nontaxable: { sl: 'neobdavčeno', en: 'non-taxable',      de: 'nicht steuerpflichtig' },
		},
		InvoiceSums: {
			AmountWithoutVAT:     { sl: 'Vrednost brez DDV EUR', en: 'Amount without Discount EUR', de: 'Wert ohne Rabatt EUR'    },
			DiscountAmount:       { sl: 'Popust EUR',            en: 'Discount EUR',                de: 'Rabatt EUR'              },
			TaxBase:              { sl: 'Davčna osnova EUR',     en: 'Total Amount EUR',            de: 'Bemessungsgrundlage EUR' },
			TaxAmount:            { sl: 'Znesek davka EUR',      en: 'VAT Amount EUR',              de: 'Steuersumme EUR'         },
			Total:                { sl: 'Skupaj za plačilo EUR', en: 'TOTAL to be Paid EUR',        de: 'Gesamtsumme EUR'         },
			AdvancePaymentAmount: { sl: 'Predplačilo EUR',       en: 'Paid in Advance EUR',         de: 'Avanszahlung EUR'        },
			RemainingAmount:      { sl: 'Ostane za plačilo EUR', en: 'Remain to be Paid EUR',       de: 'Restbetrag EUR'          },
		},
		PaymentTypesTable: {
			Advance:     { sl: 'Avans',         en: 'Advance',       de: 'Avans'         },
			PaymentType: { sl: 'Način plačila', en: 'Način plačila', de: 'Način plačila' },
		},
		AdditionalInvoiceDetails: {
			User:                  { sl: 'Blagajnik:',   en: 'Blagajnik:',   de: 'Blagajnik:'   },
			InvoiceNumberInternal: { sl: 'Interna št.:', en: 'Interna št.:', de: 'Interna št.:' },
			ZOI:                   { sl: 'ZOI:',         en: 'ZOI:',         de: 'ZOI:'         },
			EOR:                   { sl: 'EOR:',         en: 'EOR:',         de: 'EOR:'         },
			CopyCount:             { sl: 'Kopija:',      en: 'Copy:',        de: 'Kopija:'      },
		},
		AdditionalDescription: {
			Note: { sl: 'Opomba:', en: 'Opomba:', de: 'Opomba:' },
		},
	},
};
