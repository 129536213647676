import React, { useFfect } from 'react';

import moment from 'moment';

import PrintHelper   from '../../helpers/Print';
import InvoiceHelper from '../../helpers/Invoice';
import T             from '../../helpers/Translation';

import {
	CompanyDetails,
	Logo,
	Footer,
} from './Common';

function formatNumber(val, decimal_digit_count) {
	decimal_digit_count = decimal_digit_count === undefined ? 2 : decimal_digit_count;
	
	let rounded_val = Math.properRound(parseFloat(val), decimal_digit_count);
	
	return rounded_val
		.toLocaleString(
			undefined,
			{
				minimumFractionDigits: decimal_digit_count,
				maximumFractionDigits: decimal_digit_count,
			}
		);
}

function CustomerDetails(props) {
	let customer_name = (
		(props.customer.name ?? '')
		+ ' ' +
		(props.customer.surname ?? '')
	).trim();
	if (props.customer.type == 'company') {
		if (props.customer.display_company_long_name_on_invoices && props.customer.company_long_name.length > 0) {
			customer_name = props.customer.company_long_name ?? '';
		}
		else {
			customer_name = props.customer.company_name ?? '';
		}
	}
	
	return <div className='customer-details'>
		<div className='emphasized font-large-1 mb-05-line'>{customer_name.trim()}</div>
		<div>{props.customer.address ?? ''}</div>
		<div>{(
			(props.customer.post_code ?? '')
			+ ' ' +
			(props.customer.post_office ?? '')
		).trim()}</div>
		<div className='mb-2-line'>{
			props.customer_country === null || props.customer_country === undefined ? '' : props.customer_country.common_name_international
		}</div>
		<div className='mb-05-line'>{
			props.customer.vat_registered === null ? '' :
			(
				(props.customer.vat_registered ? T.Print.CustomerDetails.VATNumber[props.lang] : T.Print.CustomerDetails.TaxNumber[props.lang]) +
				' ' +
				props.customer.vat_prefix +
				props.customer.tax_number
			)
		}</div>
		<div className='font-small'>{
			props.customer.internal_code === null || props.customer.internal_code === undefined ? '' :
			(T.Print.CustomerDetails.CustomerInternalCode[props.lang] + ' ' + (props.customer.internal_code ?? ''))
		}</div>
		{props.item.delivery_address.trim().length == 0 ? null :
			<div className='font-small mt-1'>
				<div className='emphasized'>Naslov za dostavo:</div>
				{props.item.delivery_address.trim().split('\n').map((line, idx) => <div key={idx}>{ line }</div>)}
			</div>
		}
	</div>;
}
function MainInvoiceDetails(props) {
	// prepare data
	let place = null;
	place = props.company_data.subject_city;
	
	let timestamp_format = T.Print.MainInvoiceDetails.DateFormat[props.lang];
	
	const document_date_timestamp = props.item.document_date === null ? '' : moment(props.item.document_date).format(timestamp_format);
	const order_date_timestamp    = props.item.order_date    === null ? '' : moment(props.item.order_date   ).format(timestamp_format);
	
	const title = T.Print.MainInvoiceDetails.InvoiceTitles['delivery-note'];
	
	// prepare user fragment
	let user_fragment = null;
	let user = null;
	for (let id_user in props.users) {
		if (props.users[id_user].username == props.item.username) {
			user = props.users[id_user];
			break;
		}
	}
	
	if (user !== null) {
		user_fragment = <>
			<div>{ T.Print.MainInvoiceDetails.UserIssuedBy   [props.lang] }</div>
			<div>{ (user.name + ' ' + (
				user.surname.length == 0 ? '' : (user.surname.substring(0, 1) + '.')
			)).trim() }</div>
		</>;
	}
	
	return <div className='main-invoice-details'>
		<div style={{ gridColumn: '1 / 3' }} className='font-large-6 deemphasized mb-05-line'>
			{ title === undefined ? '' : title[props.lang] }
		</div>
		
		<div className='emphasized mb-05-line font-large-1'>{T.Print.MainInvoiceDetails.InvoiceNumber[props.lang]}</div>
		<div className='emphasized mb-05-line font-large-1'>{props.item.document_number}</div>
		
		<div>{T.Print.MainInvoiceDetails.Place[props.lang]}</div>
		<div>{place}</div>
		
		<div>{'Datum dobavnice'}</div>
		<div>{document_date_timestamp}</div>
		
		<div>{'Št. naročila'}</div>
		<div>{props.item.order_number}</div>
		
		<div>{'Datum naročila'}</div>
		<div>{order_date_timestamp}</div>
		
		{ user_fragment }
	</div>;
}
function InvoiceItemsTable(props) {
	let items = [];
	
	items.push([
		T.Print.InvoiceItemsTable.ItemInternalCode     [props.lang],
		'EAN',
		T.Print.InvoiceItemsTable.ItemTitle            [props.lang],
		T.Print.InvoiceItemsTable.Quantity             [props.lang],
		T.Print.InvoiceItemsTable.ItemMeasuringUnitCode[props.lang],
	]);
	
	const item_items = props.item.items;
	
	const sum_by_measuring_unit = {};
	
	for (let i=0; i<item_items.length; i++) {
		const item           = item_items[i];
		const item_item      = props.items[item.id_item];
		const measuring_unit = props.measuring_units[item_item.id_measuring_unit];
		
		items.push([
			item_item.internal_code,
			item_item.ean,
			item_item.title,
			formatNumber(item.quantity, measuring_unit.decimal_digit_count),
			measuring_unit.title,
		]);
		
		if (sum_by_measuring_unit[measuring_unit.id_measuring_unit] === undefined) {
			sum_by_measuring_unit[measuring_unit.id_measuring_unit] = {
				measuring_unit,
				quantity: 0,
			};
		}
		sum_by_measuring_unit[measuring_unit.id_measuring_unit].quantity += parseFloat(item.quantity);
	}
	
	return <div className='delivery-note-items-table'>
		{items.map((item, idx) => {
			const cls = idx == 0 ? 'cell header-cell' : 'cell';
			
			if (item.length == 1) {
				return <React.Fragment key={'item-' + idx}>
					<div className={cls}></div>
					<div className={cls + ' second-description-cell font-small'}>
						{item[0]}
					</div>
				</React.Fragment>;
			}
			
			return <React.Fragment key={'item-' + idx}>
				<div className={cls + ' wrap'}      >{item[0]}</div>
				<div className={cls + ' wrap'}      >{item[1]}</div>
				<div className={cls + ' wrap'}      >{item[2]}</div>
				<div className={cls + ' text-right'}>{item[3]}</div>
				<div className={cls}                >{item[4]}</div>
			</React.Fragment>
		})}
		
		{Object.values(sum_by_measuring_unit).map((item, idx) => {
			const cls = idx == 0 ? 'first' : '';
			const measuring_unit = item.measuring_unit;
			
			return <React.Fragment key={'sum-' + idx}>
				<div className={cls + ' cell sum wrap title'}>{ idx == 0 ? 'Skupna količina' : '' }</div>
				<div className={cls + ' cell sum text-right'}>{ formatNumber(item.quantity, measuring_unit.decimal_digit_count) }</div>
				<div className={cls + ' cell sum'}           >{ measuring_unit.title }</div>
			</React.Fragment>;
		})}
	</div>;
}
function AdditionalDescription(props) {
	let print_final_text = props.settings.print_final_text;
	if (props.lang == 'en') {
		print_final_text = props.settings.print_final_text_en;
	}
	else if (props.lang == 'de') {
		print_final_text = props.settings.print_final_text_de;
	}
	
	print_final_text = print_final_text || '';
	print_final_text = print_final_text
		.replaceAll('<right>', '<div style="text-align: right;">')
		.replaceAll('</right>', '</div>');
	
	return <div className='additional-description'>
		{props.item.note === null || props.item.note.length == 0 ? null :
			<div className='mb-1-line'>{T.Print.AdditionalDescription.Note[props.lang] + ' ' + props.item.note}</div>
		}
		
		{props.lang != 'sl' || props.settings.subject_vat_registered !== false ? null :
			<div className='mb-1-line'>{props.settings.subject_vat_registered_reason}</div>
		}
		
		{print_final_text === undefined || print_final_text.length == 0 ? null :
			<div className='mb-1-line'>
				<div dangerouslySetInnerHTML={{ __html: print_final_text }}></div>
			</div>
		}
	</div>;
}

export default function DeliveryNote(props) {
	const {
		company_data,
		item,
		payment_types,
		id_advance_invoice_consumption_by_id_consumer_invoices,
		invoices,
		advance_invoice_consumptions,
		users,
		invoice_fiscal_verifications,
		customer,
		customer_country,
		settings,
		lang,
		items,
		measuring_units,
	} = props;
	
	let cls = 'print-document print-delivery-note';
	if (settings.logo_position == 'left') {
		cls += ' reverse-logo';
	}
	else if (settings.logo_position == 'hidden') {
		cls += ' hide-logo';
	}
	
	return <div className={cls}>
		<div className='print-delivery-note-main'>
			<CompanyDetails     lang={lang} company_data={company_data} />
			<Logo               lang={lang} company_data={company_data} />
			<CustomerDetails    lang={lang} item={item} customer={customer} customer_country={customer_country} />
			<MainInvoiceDetails lang={lang} item={item} company_data={company_data} users={users} />
			<InvoiceItemsTable  lang={lang} item={item} items={items} measuring_units={measuring_units} />
			<AdditionalDescription lang={lang} item={item} settings={settings} />
		</div>
		{!settings.print_footer_a4 ? null : <Footer lang={lang} settings={settings} />}
	</div>;
}
