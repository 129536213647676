import { createSlice } from '@reduxjs/toolkit'
import uuid from 'uuid';

function addItem(state_key_name, key_name, item, state) {
	if (state[state_key_name] === null) state[state_key_name] = {};
	
	if (item[key_name] == -1) {
		item[key_name] = uuid.v4();
	}
	
	state[state_key_name][item[key_name]] = item;
}
function addItems(state_key_name, key_name, items, state) {
	if (state[state_key_name] === null) state[state_key_name] = {};
	
	if (state_key_name == 'stock_state') {
		state[state_key_name] = items;
	}
	else {
		for (let i=0; i<items.length; i++) {
			state[state_key_name][items[i][key_name]] = items[i];
		}
	}
}
function addItemsByDate(state_key_name, key_name, payload, state) {
	const date  = payload.date;
	const items = payload.items;
	
	if (state['stock_inventory_by_date']       === null     ) state['stock_inventory_by_date']       = {};
	if (state['stock_inventory_by_date'][date] === undefined) state['stock_inventory_by_date'][date] = {
		stock_inventory_items:                   {},
		stock_inventory_detailed:                {},
		stock_inventory_detailed_acquired_items: {},
		stock_inventory_detailed_spent_items:    {},
		stock_state:                             {},
	};
	
	if (state_key_name == 'stock_state') {
		state['stock_inventory_by_date'][date][state_key_name] = items;
	}
	else {
		for (let i=0; i<items.length; i++) {
			state['stock_inventory_by_date'][date][state_key_name][items[i][key_name]] = items[i];
		}
	}
}
function addLastStockDataItemsByDate(state_key_name, key_name, payload, state) {
	const date  = payload.date;
	const items = payload.items;
	
	if (state['last_stock_data_by_date']       === null     ) state['last_stock_data_by_date']       = {};
	if (state['last_stock_data_by_date'][date] === undefined) state['last_stock_data_by_date'][date] = {};
	
	if (state_key_name == 'last_stock_data') {
		state['last_stock_data_by_date'][date][state_key_name] = items;
	}
	else {
		for (let i=0; i<items.length; i++) {
			state['last_stock_data_by_date'][date][state_key_name][items[i][key_name]] = items[i];
		}
	}
}
function deleteItemFromState(state_key_name, payload, state) {
	delete state[state_key_name][payload];
}

const WarehouseSlice = createSlice({
	name: 'warehouses',
	initialState: {}, // defined in AppContainer.js
	reducers: {
		addStockAcquisitionDocument:  (state, action) => addItem ('stock_acquisition_documents', 'id_stock_acquisition_document', action.payload, state),
		addStockAcquisitionDocuments: (state, action) => addItems('stock_acquisition_documents', 'id_stock_acquisition_document', action.payload, state),
		addDeliveryNote:              (state, action) => addItem ('delivery_notes',              'id_delivery_note',              action.payload, state),
		addDeliveryNotes:             (state, action) => addItems('delivery_notes',              'id_delivery_note',              action.payload, state),
		addStockInventoryItems:                 (state, action) => addItems('stock_inventory_items',                   'id_item--id_warehouse', action.payload, state),
		addStockInventoryDetailed:              (state, action) => addItems('stock_inventory_detailed',                'id_item',               action.payload, state),
		addStockInventoryDetailedAcquiredItems: (state, action) => addItems('stock_inventory_detailed_acquired_items', 'id_item--id_warehouse', action.payload, state),
		addStockInventoryDetailedSpentItems:    (state, action) => addItems('stock_inventory_detailed_spent_items',    'id_item--id_warehouse', action.payload, state),
		addStockInventoryStockState:            (state, action) => addItems('stock_state',                             '',                      action.payload, state),
		addStockInventoryItemsByDate:                 (state, action) => addItemsByDate('stock_inventory_items',                   'id_item--id_warehouse', action.payload, state),
		addStockInventoryDetailedByDate:              (state, action) => addItemsByDate('stock_inventory_detailed',                'id_item',               action.payload, state),
		addStockInventoryDetailedAcquiredItemsByDate: (state, action) => addItemsByDate('stock_inventory_detailed_acquired_items', 'id_item--id_warehouse', action.payload, state),
		addStockInventoryDetailedSpentItemsByDate:    (state, action) => addItemsByDate('stock_inventory_detailed_spent_items',    'id_item--id_warehouse', action.payload, state),
		addStockInventoryStockStateByDate:            (state, action) => addItemsByDate('stock_state',                             '',                      action.payload, state),
		addLastStockDataByDate:                       (state, action) => addLastStockDataItemsByDate('last_stock_data',            '',                      action.payload, state),
	}
});

export const {
	addStockAcquisitionDocument,
	addStockAcquisitionDocuments,
	addDeliveryNote,
	addDeliveryNotes,
	addStockInventoryItems,
	addStockInventoryDetailed,
	addStockInventoryDetailedAcquiredItems,
	addStockInventoryDetailedSpentItems,
	addStockInventoryStockState,
	addStockInventoryItemsByDate,
	addStockInventoryDetailedByDate,
	addStockInventoryDetailedAcquiredItemsByDate,
	addStockInventoryDetailedSpentItemsByDate,
	addStockInventoryStockStateByDate,
	addLastStockDataByDate,
} = WarehouseSlice.actions;
export default WarehouseSlice.reducer;
