import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
	Icon,
	Button,
} from '@blueprintjs/core';

import deepEqual from 'deep-equal';

import { addDocumentType } from '../../slices/DocumentSlice';
import { saveDocumentType } from '../../api/Documents';

import LocalStorageHelper from '../../helpers/LocalStorageHelper';

import { ResultHeader, Result } from '../code_tables/ResultComponents';

import EditSettingsItemDocumentDialog from './EditSettingsItemDocumentDialog';

function Results(props) {
	const {
		item,
		openEditSettingsItemDialog,
		document_types,
	} = props;
	
	return <div className='results documents mb-4'>
		<div className='results-table mt-4'>
			<ResultHeader columnIdx={1} title='Dokumenti' />
			<ResultHeader columnIdx={2} title='Nastavitev' />
			<ResultHeader columnIdx={3} title='SL' />
			<ResultHeader columnIdx={4} title='EN' />
			<ResultHeader columnIdx={5} title='DE' />
			<ResultHeader columnIdx={6} title='POS' />
		</div>
		
		<div className='results-table' key={'settings-result-1'}>
			{[
				{ key: 'cash-invoice',                        title: 'Blagajniški račun',    display_pos_field: true  },
				{ key: 'cash-prepayment-invoice',             title: 'Račun za predplačilo', display_pos_field: true  },
				//{ key: 'cash-register-invoice-income',        title: '',                     display_pos_field: false },
				//{ key: 'cash-register-invoice-income-manual', title: '',                     display_pos_field: false },
				//{ key: 'cash-register-invoice-outlay',        title: '',                     display_pos_field: false },
				//{ key: 'cash-register-invoice-outlay-manual', title: '',                     display_pos_field: false },
				//{ key: 'cash-register-journal',               title: '',                     display_pos_field: false },
				{ key: 'credit-note',                         title: 'Dobropis',             display_pos_field: false },
				//{ key: 'delivery-report',                     title: '',                     display_pos_field: false },
				//{ key: 'gift-voucher',                        title: '',                     display_pos_field: false },
				//{ key: 'guest-book',                          title: '',                     display_pos_field: false },
				{ key: 'invoice',                             title: 'Faktura',              display_pos_field: false },
				{ key: 'offer',                               title: 'Ponudba',              display_pos_field: false },
				{ key: 'prepayment-invoice',                  title: 'Avansni račun',        display_pos_field: false },
				//{ key: 'proforma-invoice-book',               title: '',                     display_pos_field: false },
				//{ key: 'sales-invoice-book',                  title: '',                     display_pos_field: false },
				//{ key: 'stock-acquisition',                   title: '',                     display_pos_field: false },
				//{ key: 'stock-delivery-document',             title: '',                     display_pos_field: false },
				//{ key: 'warehouse-transfer-order',            title: '',                     display_pos_field: false },
				{ key: 'delivery-note',                       title: 'Dobavnica',            display_pos_field: false },
			].map(property => {
				let key  = property.key;
				let type = 'document';
				
				const document = document_types[key];
				
				return <React.Fragment key={'settings-result--property-' + key}>
					<Result columnIdx={1} child={
						<Button
							icon='edit'
							intent='primary'
							minimal={true}
							small={true}
							onClick={() => {
								openEditSettingsItemDialog(
									type,
									property,
									property.title,
									key
								);
							}} />
					} />
					<Result columnIdx={2} child={
						property.title
					} />
					<Result columnIdx={3} child={
						<Icon icon={document.print_final_text === null || document.print_final_text.length == 0 ? 'cross' : 'tick'} />
					} />
					<Result columnIdx={4} child={
						<Icon icon={document.print_final_text_de === null || document.print_final_text_de.length == 0 ? 'cross' : 'tick'} />
					} />
					<Result columnIdx={5} child={
						<Icon icon={document.print_final_text_en === null || document.print_final_text_en.length == 0 ? 'cross' : 'tick'} />
					} />
					<Result columnIdx={6} child={
						<Icon icon={document.print_final_text_pos === null || document.print_final_text_pos.length == 0 ? 'cross' : 'tick'} />
					} />
				</React.Fragment>;
			})}
		</div>
	</div>;
}

class DocumentSettings extends Component {
	constructor(props) {
		super(props);
		
		this.updateTableItemsFromProps   = this.updateTableItemsFromProps  .bind(this);
		this.openEditSettingsItemDialog  = this.openEditSettingsItemDialog .bind(this);
		this.closeEditSettingsItemDialog = this.closeEditSettingsItemDialog.bind(this);
		
		const {
			document_types,
		} = this.updateTableItemsFromProps(props);
		
		this.state = {
			document_types,
			edit_settings_item_dialog_open:         false,
			edit_settings_item_dialog_type:         null,
			edit_settings_item_dialog_item:         null,
			edit_settings_item_dialog_title:        '',
			edit_settings_item_dialog_property_key: null,
			edit_settings_item_dialog_display_pos:  false,
		};
	}
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		if (!deepEqual(this.props.document_types, this.state.document_types)) {
			this.setState(
				this.updateTableItemsFromProps(this.props)
			);
		}
	}
	
	updateTableItemsFromProps(props) {
		return {
			document_types: props.document_types,
		};
	}
	
	openEditSettingsItemDialog(type, item, title, property_key) {
		this.setState({
			edit_settings_item_dialog_open:         true,
			edit_settings_item_dialog_type:         type,
			edit_settings_item_dialog_item:         item,
			edit_settings_item_dialog_title:        title,
			edit_settings_item_dialog_property_key: property_key,
			edit_settings_item_dialog_display_pos:  item.display_pos_field,
		});
	}
	async closeEditSettingsItemDialog(item) {
		this.setState({
			edit_settings_item_dialog_open:         false,
			edit_settings_item_dialog_type:         null,
			edit_settings_item_dialog_item:         null,
			edit_settings_item_dialog_title:        '',
			edit_settings_item_dialog_property_key: null,
			edit_settings_item_dialog_display_pos:  false,
		});
		
		if (item !== undefined) {
			let new_item = item;
			
			const dispatch = this.props.dispatch;
			const token    = this.props.token;
			
			new_item = await saveDocumentType(this.props.api_url, new_item, token);
			if (new_item !== null) {
				dispatch(addDocumentType({ item: new_item }));
			}
		}
	}
	
	render() {
		let edit_settings_dialog = null;
		if (this.state.edit_settings_item_dialog_open) {
			const document = this.state.document_types[this.state.edit_settings_item_dialog_property_key];
			if (document !== undefined) {
				edit_settings_dialog =
					<EditSettingsItemDocumentDialog
						item={document}
						title={this.state.edit_settings_item_dialog_title}
						display_pos={this.state.edit_settings_item_dialog_display_pos}
						closeDialog={this.closeEditSettingsItemDialog} />;
			}
		}
		
		return <>
			{edit_settings_dialog}
			
			<div className='flex flex-col flex-grow general-settings-list'>
				<div className='flex-1 pl-4 pr-4'>
					<div className='flex-grow overflow-y-auto' style={{ flexBasis: '0' }}>
						<Results
							item={this.state.item}
							openEditSettingsItemDialog={this.openEditSettingsItemDialog}
							client={this.props.client}
							document_types={this.props.document_types} />
					</div>
				</div>
			</div>
		</>;
	}
}
DocumentSettings.propTypes = {
};

function mapStateToProps(state) {
	return {
		general_settings: state.SettingsSlice.general,
		api_url:          state.SettingsSlice.api_url,
		token:            state.UserSlice.token,
		document_types:   state.DocumentSlice.document_types,
		client:           state.ConstantsSlice.client,
	};
}

export default connect(mapStateToProps)(DocumentSettings);
