import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
	Icon,
	Button,
} from '@blueprintjs/core';

import { setGeneralSettings } from '../../slices/SettingsSlice';
import { saveGeneralSettings } from '../../api/Settings';

import LocalStorageHelper from '../../helpers/LocalStorageHelper';

import { ResultHeader, Result } from '../code_tables/ResultComponents';

import EditSettingsItemDialog          from './EditSettingsItemDialog';
import EditSettingsItemVATDialog       from './EditSettingsItemVATDialog';
import EditSettingsItemFileDialog      from './EditSettingsItemFileDialog';
import EditSettingsItemSwitchDialog    from './EditSettingsItemSwitchDialog';
import EditSettingsItemNumberDialog    from './EditSettingsItemNumberDialog';
import EditSettingsItemDropdownDialog  from './EditSettingsItemDropdownDialog';
import EditSettingsItemMultilineDialog from './EditSettingsItemMultilineDialog';

const SubjectTypes = {
	'legal-person':                           'Pravna oseba',
	'sole-proprietorship-simple-bookkeeping': 'Samostojni podjetnik - enostavno knjigovodstvo',
	'sole-proprietorship-double-bookkeeping': 'Samostojni podjetnik - dvostavno knjigovodstvo',
	'public-institution':                     'Javni zavod',
	'association':                            'Društvo',
	'non-profit-organization-of-private-law': 'Nepridobitna organizacija zasebnega prava',
	'natural-person-with-activity':           'Fizične osebe z dejavnostjo',
};

const ScannerChangeCase = {
	'original':   'Brez spremembe',
	'uppercase':  'Velike črke',
	'lowercase':  'Majhne črke',
	'title-case': 'Velike začetnice',
};

function Results(props) {
	const {
		item,
		openEditSettingsItemDialog,
		subject_types,
	} = props;
	
	return <div className='results mt-4 mb-4'>
		<div className='results-table'>
			<ResultHeader columnIdx={1} title='Podatki o podjetju' />
			<ResultHeader columnIdx={2} title='Nastavitev' />
			<ResultHeader columnIdx={3} title='Vrednost' />
		</div>
		
		<div className='results-table' key={'settings-result-1'}>
			{[
				{ key: 'subject_type',                title: 'Vrsta subjekta'        },
				{ key: 'subject_long_name',           title: 'Uradni naziv subjekta' },
				{ key: 'subject_short_name',          title: 'Krajši naziv subjekta' },
				{ key: 'subject_address',             title: 'Naslov'                },
				{ key: 'subject_post_code',           title: 'Poštna številka'       },
				{ key: 'subject_city',                title: 'Kraj'                  },
				{ key: 'subject_email',               title: 'E-pošta'               },
				{ key: 'subject_website',             title: 'Spletna stran'         },
				{ key: 'subject_phone_1',             title: 'Telefonska številka'   },
				{ key: 'subject_phone_2',             title: 'Mobilna številka'      },
				{ key: 'subject_trr_1',               title: 'TRR (primarni)'        },
				{ key: 'subject_trr_2',               title: 'TRR (dodatni)'         },
				{ key: 'subject_tax_number',          title: 'Davčna številka'       },
				{ key: 'vat',                         title: 'Zavezanec za DDV'      },
				{ key: 'subject_registration_number', title: 'Matična številka'      },
				{ key: 'logo',                        title: 'Logo'                  },
				{ key: 'footer',                      title: 'Tekst v nogi'          },
				{ key: 'tourist_tax_text',            title: 'Tekst za TT'           },
			].map(property => {
				let value = item[property.key];
				let type  = 'general';
				
				if (property.key == 'subject_type') {
					value = subject_types[value];
					type  = 'dropdown';
				}
				else if (property.key == 'logo') {
					value = value === null || value === undefined || value.length < 60 ? value :
						value.substring(0, 20) +
						'...' +
						value.substring(value.length - 20);
					type = 'file';
				}
				else if (property.key == 'footer' || property.key == 'tourist_tax_text') {
					value = (value === null || value === undefined ? '' : value).replace('\n', ' ');
					type = 'multiline';
				}
				else if (property.key == 'vat') {
					value = item.subject_vat_registered === null ? '' : <Icon icon={item.subject_vat_registered ? 'tick' : 'cross'} />;
					if (value != '') {
						value =
							<div className='flex flex-row'>
								<div>{ value }</div>
								<div className='ml-1'>{ item.subject_vat_registered ? item.subject_vat_number : item.subject_vat_registered_reason }</div>
							</div>;
					}
					type = 'vat';
				}
				
				return <React.Fragment key={'settings-result--property-' + property.key}>
					<Result columnIdx={1} child={
						<Button
							icon='edit'
							intent='primary'
							minimal={true}
							small={true}
							onClick={() => {
								openEditSettingsItemDialog(
									type,
									item,
									property.key
								);
							}} />
					} />
					<Result columnIdx={2} child={
						property.title
					} />
					<Result columnIdx={3} child={
						value
					} />
				</React.Fragment>;
			})}
		</div>
		
		<div className='results-table mt-4'>
			<ResultHeader columnIdx={1} title='Davčna blagajna' />
			<ResultHeader columnIdx={2} title='Nastavitev' />
			<ResultHeader columnIdx={3} title='Vrednost' />
		</div>
		
		<div className='results-table' key={'settings-result-2'}>
			{[
				{ key: 'certificate',          title: 'Certifikat'          },
				{ key: 'certificate_password', title: 'Geslo za certifikat' },
			].map(property => {
				let value = item[property.key];
				let type  = 'general';
				
				if (property.key == 'subject_type') {
					value = subject_types[value];
					type  = 'dropdown';
				}
				else if (property.key == 'certificate') {
					value = value === null || value === undefined || value.length < 60 ? value :
						value.substring(0, 20) +
						'...' +
						value.substring(value.length - 20);
					type = 'file';
				}
				
				return <React.Fragment key={'settings-result--property-' + property.key}>
					<Result columnIdx={1} child={
						<Button
							icon='edit'
							intent='primary'
							minimal={true}
							small={true}
							onClick={() => {
								openEditSettingsItemDialog(
									type,
									item,
									property.key
								);
							}} />
					} />
					<Result columnIdx={2} child={
						property.title
					} />
					<Result columnIdx={3} child={
						value
					} />
				</React.Fragment>;
			})}
		</div>
		
		<div className='results-table mt-4'>
			<ResultHeader columnIdx={1} title='Razno' />
			<ResultHeader columnIdx={2} title='Nastavitev' />
			<ResultHeader columnIdx={3} title='Vrednost' />
		</div>
		
		<div className='results-table' key={'settings-result-4'}>
			{[
				{ key: 'save_path',                         title: 'Pot za shranjevanje dokumentov',   value: LocalStorageHelper.GetValue(props.client + '__save_path') },
			].map(property => {
				let key   = property.key;
				let value = property.value;
				let type  = 'general';
				
				let new_item = item;
				let extra    = {};
				if (key == 'save_path') {
					new_item      = {};
					new_item[key] = value;
				}
				
				return <React.Fragment key={'settings-result--property-' + key}>
					<Result columnIdx={1} child={
						<Button
							icon='edit'
							intent='primary'
							minimal={true}
							small={true}
							onClick={() => {
								openEditSettingsItemDialog(
									type,
									new_item,
									key,
									extra
								);
							}} />
					} />
					<Result columnIdx={2} child={
						property.title
					} />
					<Result columnIdx={3} child={
						value
					} />
				</React.Fragment>;
			})}
		</div>
		
		<div className='results-table mt-4'>
			<ResultHeader columnIdx={1} title='OCR' />
			<ResultHeader columnIdx={2} title='Nastavitev' />
			<ResultHeader columnIdx={3} title='Vrednost' />
		</div>
		
		<div className='results-table' key={'settings-result-5'}>
			{[
				{ key: 'scanner_change_case', title: 'Sprememba velikosti črk', value: ScannerChangeCase[item['scanner_change_case']] },
			].map(property => {
				let key   = property.key;
				let value = property.value;
				let type  = 'general';
				
				if (property.key == 'scanner_change_case') {
					type = 'dropdown';
				}
				
				let new_item = item;
				
				return <React.Fragment key={'settings-result--property-' + key}>
					<Result columnIdx={1} child={
						<Button
							icon='edit'
							intent='primary'
							minimal={true}
							small={true}
							onClick={() => {
								openEditSettingsItemDialog(
									type,
									new_item,
									key
								);
							}} />
					} />
					<Result columnIdx={2} child={
						property.title
					} />
					<Result columnIdx={3} child={
						value
					} />
				</React.Fragment>;
			})}
		</div>
	</div>;
}

class GeneralSettings extends Component {
	constructor(props) {
		super(props);
		
		this.updateTableItemsFromProps   = this.updateTableItemsFromProps  .bind(this);
		this.openEditSettingsItemDialog  = this.openEditSettingsItemDialog .bind(this);
		this.closeEditSettingsItemDialog = this.closeEditSettingsItemDialog.bind(this);
		
		const {
			item,
		} = this.updateTableItemsFromProps(props);
		
		this.state = {
			item,
			edit_settings_item_dialog_open:         false,
			edit_settings_item_dialog_item:         null,
			edit_settings_item_dialog_property_key: null,
			edit_settings_item_dialog_extra:        null,
		};
	}
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.general_settings != this.state.item) {
			this.setState(
				this.updateTableItemsFromProps(this.props)
			);
		}
	}
	
	updateTableItemsFromProps(props) {
		return {
			item: props.general_settings,
		};
	}
	
	openEditSettingsItemDialog(type, item, property_key, extra) {
		this.setState({
			edit_settings_item_dialog_open:         true,
			edit_settings_item_dialog_type:         type,
			edit_settings_item_dialog_item:         item,
			edit_settings_item_dialog_property_key: property_key,
			edit_settings_item_dialog_extra:        extra ?? {},
		});
	}
	async closeEditSettingsItemDialog(item, property_key, new_value) {
		this.setState({
			edit_settings_item_dialog_open:         false,
			edit_settings_item_dialog_type:         null,
			edit_settings_item_dialog_item:         null,
			edit_settings_item_dialog_property_key: null,
			edit_settings_item_dialog_extra:        null,
		});
		
		if (item !== undefined) {
			if (property_key == 'save_path') {
				LocalStorageHelper.SetValue(this.props.client + '__save_path', new_value);
			}
			else if (property_key == 'vat') {
				let new_item = {...item};
				
				new_item.subject_vat_registered        = new_value.subject_vat_registered;
				new_item.subject_vat_registered_reason = new_value.subject_vat_registered_reason;
				new_item.subject_vat_number            = new_value.subject_vat_number;
				
				this.props.dispatch(setGeneralSettings(new_item));
				
				const dispatch = this.props.dispatch;
				const token    = this.props.token;
				
				new_item = await saveGeneralSettings(this.props.api_url, new_item, token);
				if (new_item !== null) {
					dispatch(setGeneralSettings(new_item));
				}
			}
			else {
				let new_item = {...item};
				new_item[property_key] = new_value;
				
				this.props.dispatch(setGeneralSettings(new_item));
				
				const dispatch = this.props.dispatch;
				const token    = this.props.token;
				
				new_item = await saveGeneralSettings(this.props.api_url, new_item, token);
				if (new_item !== null) {
					dispatch(setGeneralSettings(new_item));
				}
			}
		}
	}
	
	render() {
		let edit_settings_dialog = null;
		if (this.state.edit_settings_item_dialog_open) {
			if (this.state.edit_settings_item_dialog_type == 'dropdown') {
				let options = SubjectTypes;
				if (this.state.edit_settings_item_dialog_property_key == 'scanner_change_case') {
					options = ScannerChangeCase;
				}
				
				edit_settings_dialog =
					<EditSettingsItemDropdownDialog
						item={this.state.edit_settings_item_dialog_item}
						property_key={this.state.edit_settings_item_dialog_property_key}
						extra={this.state.edit_settings_item_dialog_extra}
						closeDialog={this.closeEditSettingsItemDialog}
						options={options} />
			}
			else if (this.state.edit_settings_item_dialog_type == 'file') {
				edit_settings_dialog =
					<EditSettingsItemFileDialog
						item={this.state.edit_settings_item_dialog_item}
						property_key={this.state.edit_settings_item_dialog_property_key}
						extra={this.state.edit_settings_item_dialog_extra}
						closeDialog={this.closeEditSettingsItemDialog} />;
			}
			else if (this.state.edit_settings_item_dialog_type == 'multiline') {
				edit_settings_dialog =
					<EditSettingsItemMultilineDialog
						item={this.state.edit_settings_item_dialog_item}
						property_key={this.state.edit_settings_item_dialog_property_key}
						extra={this.state.edit_settings_item_dialog_extra}
						closeDialog={this.closeEditSettingsItemDialog} />;
			}
			else if (this.state.edit_settings_item_dialog_type == 'bool') {
				edit_settings_dialog =
					<EditSettingsItemSwitchDialog
						item={this.state.edit_settings_item_dialog_item}
						property_key={this.state.edit_settings_item_dialog_property_key}
						extra={this.state.edit_settings_item_dialog_extra}
						closeDialog={this.closeEditSettingsItemDialog} />;
			}
			else if (this.state.edit_settings_item_dialog_type == 'vat') {
				edit_settings_dialog =
					<EditSettingsItemVATDialog
						item={this.state.edit_settings_item_dialog_item}
						property_key={this.state.edit_settings_item_dialog_property_key}
						extra={this.state.edit_settings_item_dialog_extra}
						closeDialog={this.closeEditSettingsItemDialog} />;
			}
			else if (this.state.edit_settings_item_dialog_type == 'number') {
				edit_settings_dialog =
					<EditSettingsItemNumberDialog
						item={this.state.edit_settings_item_dialog_item}
						property_key={this.state.edit_settings_item_dialog_property_key}
						extra={this.state.edit_settings_item_dialog_extra}
						closeDialog={this.closeEditSettingsItemDialog} />;
			}
			else {
				edit_settings_dialog =
					<EditSettingsItemDialog
						item={this.state.edit_settings_item_dialog_item}
						property_key={this.state.edit_settings_item_dialog_property_key}
						extra={this.state.edit_settings_item_dialog_extra}
						closeDialog={this.closeEditSettingsItemDialog} />;
			}
		}
		
		return <>
			{edit_settings_dialog}
			
			<div className='flex flex-col flex-grow general-settings-list'>
				<div className='flex-1 pl-4 pr-4'>
					<div className='flex-grow overflow-y-auto' style={{ flexBasis: '0' }}>
						<Results
							item={this.state.item}
							openEditSettingsItemDialog={this.openEditSettingsItemDialog}
							subject_types={SubjectTypes}
							client={this.props.client} />
					</div>
				</div>
			</div>
		</>;
	}
}
GeneralSettings.propTypes = {
};

function mapStateToProps(state) {
	return {
		general_settings: state.SettingsSlice.general,
		api_url:          state.SettingsSlice.api_url,
		token:            state.UserSlice.token,
		client:           state.ConstantsSlice.client,
	};
}

export default connect(mapStateToProps)(GeneralSettings);
