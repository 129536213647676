import {
	addStockAcquisitionDocuments,
	addStockAcquisitionDocument,
	addDeliveryNotes,
	addDeliveryNote,
	addStockInventoryItems,
	addStockInventoryDetailed,
	addStockInventoryDetailedAcquiredItems,
	addStockInventoryDetailedSpentItems,
	addStockInventoryStockState,
	addStockInventoryItemsByDate,
	addStockInventoryDetailedByDate,
	addStockInventoryDetailedAcquiredItemsByDate,
	addStockInventoryDetailedSpentItemsByDate,
	addStockInventoryStockStateByDate,
	addLastStockDataByDate,
} from '../slices/WarehouseSlice';
import {
	setUser,
} from '../slices/UserSlice';

export async function loadStockAcquisitionDocuments(api_url, dispatch, token) {
	let response = await fetch(api_url + 'Warehouses/v1/stock-acquisition-documents', { headers: { 'Authorization': 'Bearer ' + token } });
	let items = await response.json();
	
	if (items.error_code !== undefined) {
		if (items.error_code == 20001) { // invalid token
			dispatch(setUser({ user_info: null, token: null }));
		}
	}
	else {
		dispatch(addStockAcquisitionDocuments(items));
	}
}

export async function loadStockAcquisitionDocument(api_url, id_item, dispatch, token) {
	let response = await fetch(api_url + 'Warehouses/v1/stock-acquisition-document?id_stock_acquisition_document=' + id_item, { headers: { 'Authorization': 'Bearer ' + token } });
	let item = await response.json();
	
	if (item.error_code !== undefined) {
		if (item.error_code == 20001) { // invalid token
			dispatch(setUser({ user_info: null, token: null }));
		}
	}
	else {
		dispatch(addStockAcquisitionDocument(item));
	}
	
	return item;
}

export async function saveStockAcquisitionDocument(api_url, item, token) {
	const form_data = new FormData();
	form_data.set('item', JSON.stringify(item));
	
	let response = await fetch(api_url + 'Warehouses/v1/save-stock-acquisition-document', {
		method:  'post',
		body:    form_data,
		headers: { 'Authorization': 'Bearer ' + token },
	});
	let new_item = await response.json();
	
	if (new_item.error_code !== undefined) {
		if (new_item.error_code == 20001) { // invalid token
			//dispatch(setUser({ user_info: null, token: null }));
		}
	}
	else {
		return new_item;
	}
	return null;
}

export async function loadDeliveryNotes(api_url, dispatch, token) {
	let response = await fetch(api_url + 'Warehouses/v1/delivery-notes', { headers: { 'Authorization': 'Bearer ' + token } });
	let items = await response.json();
	
	if (items.error_code !== undefined) {
		if (items.error_code == 20001) { // invalid token
			dispatch(setUser({ user_info: null, token: null }));
		}
	}
	else {
		dispatch(addDeliveryNotes(items));
	}
}

export async function loadDeliveryNote(api_url, id_item, dispatch, token) {
	let response = await fetch(api_url + 'Warehouses/v1/delivery-note?id_delivery_note=' + id_item, { headers: { 'Authorization': 'Bearer ' + token } });
	let item = await response.json();
	
	if (item.error_code !== undefined) {
		if (item.error_code == 20001) { // invalid token
			dispatch(setUser({ user_info: null, token: null }));
		}
	}
	else {
		dispatch(addDeliveryNote(item));
	}
	
	return item;
}

export async function saveDeliveryNote(api_url, item, token) {
	const form_data = new FormData();
	form_data.set('item', JSON.stringify(item));
	
	let response = await fetch(api_url + 'Warehouses/v1/save-delivery-note', {
		method:  'post',
		body:    form_data,
		headers: { 'Authorization': 'Bearer ' + token },
	});
	let new_item = await response.json();
	
	if (new_item.error_code !== undefined) {
		if (new_item.error_code == 20001) { // invalid token
			//dispatch(setUser({ user_info: null, token: null }));
		}
	}
	else {
		return new_item;
	}
	return null;
}

export async function loadStockInventoryItems(api_url, dispatch, token) {
	let response = await fetch(api_url + 'Warehouses/v1/stock-inventory', { headers: { 'Authorization': 'Bearer ' + token } });
	let items = await response.json();
	
	if (items.error_code !== undefined) {
		if (items.error_code == 20001) { // invalid token
			dispatch(setUser({ user_info: null, token: null }));
		}
	}
	else {
		dispatch(addStockInventoryItems                (items.summary       ));
		dispatch(addStockInventoryDetailed             (items.detailed      ));
		dispatch(addStockInventoryDetailedAcquiredItems(Object.values(items.acquired_items)));
		dispatch(addStockInventoryDetailedSpentItems   (Object.values(items.spent_items   )));
		dispatch(addStockInventoryStockState           (items.stock         ));
	}
}

export async function loadStockInventoryItemsByDate(api_url, dispatch, token, date) {
	let response = await fetch(api_url + 'Warehouses/v1/stock-inventory?until_date=' + date, { headers: { 'Authorization': 'Bearer ' + token } });
	let items = await response.json();
	
	if (items.error_code !== undefined) {
		if (items.error_code == 20001) { // invalid token
			dispatch(setUser({ user_info: null, token: null }));
		}
	}
	else {
		dispatch(addStockInventoryItemsByDate                ({ date, items: items.summary                       }));
		dispatch(addStockInventoryDetailedByDate             ({ date, items: items.detailed                      }));
		dispatch(addStockInventoryDetailedAcquiredItemsByDate({ date, items: Object.values(items.acquired_items) }));
		dispatch(addStockInventoryDetailedSpentItemsByDate   ({ date, items: Object.values(items.spent_items)    }));
		dispatch(addStockInventoryStockStateByDate           ({ date, items: items.stock                         }));
	}
}

export async function loadLastStockDataByDate(api_url, dispatch, token, date) {
	let response = await fetch(api_url + 'Warehouses/v1/last-stock-data?until_date=' + date, { headers: { 'Authorization': 'Bearer ' + token } });
	let items = await response.json();
	
	if (items.error_code !== undefined) {
		if (items.error_code == 20001) { // invalid token
			dispatch(setUser({ user_info: null, token: null }));
		}
	}
	else {
		dispatch(addLastStockDataByDate({ date, items }));
	}
}

